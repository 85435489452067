import SVG from '@axis/assets/logo.svg';
import { useIsMobile } from '@axis/hooks/mobile';

export interface LogoProps {
  className?: string;
}
export function Logo({ className }: LogoProps) {
  const isMobile = useIsMobile();
  return (
    <div className={`${className} inline-flex items-center`.trim()}>
      <SVG className="navbar-logo h-14 w-14" viewBox="0 0 640.138 640.138" />
      {!isMobile && <h1 className="font-serif font-bold text-champagne-300 text-2xl m-0 px-6">HoneyMart</h1>}
    </div>
  );
}
