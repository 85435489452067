import {
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
} from 'react';
import anime, {
  AnimeAnimParams,
  AnimeParams,
  AnimeTimelineInstance,
} from 'animejs';
import { isSSR } from '@axis/utils/ssr';

export interface AnimationState {
  anime: typeof anime;
  timelines: { [id: string]: AnimeTimelineInstance };
  newTimeline: (id: string, params: AnimeParams) => AnimeTimelineInstance|null;
  add: (id: string, params: AnimeAnimParams) => AnimeTimelineInstance|null;
  play: (id: string) => void;
  pause: (id: string) => void;
  reverse: (id: string) => void;
  seek: (id: string, seekPoint: number) => void;

}

const initialState: AnimationState = {
  anime,
  timelines: {},
  newTimeline: (id: string, params: AnimeParams) => null,
  add: (id: string, params: AnimeAnimParams) => null,
  play: (id: string) => null,
  pause: (id: string) => null,
  reverse: (id: string) => null,
  seek: (id: string, seekPoint: number) => null,
};

export const AnimationContext = createContext<AnimationState>(initialState);
const { Provider } = AnimationContext;

export type AnimationAction = {
  type: 'ADD_NEW_TIMELINE',
  payload: {
    id: string,
    params: AnimeParams,
  }
}

export function AnimationProvider({ children }: PropsWithChildren<unknown>) {
  const timelines = useRef<{ [id: string]: AnimeTimelineInstance }>({});
  if (isSSR()) {
    return (
      <Provider value={initialState}>{children}</Provider>
    );
  }

  const store: AnimationState = {
    ...initialState,
    timelines: timelines.current,
    newTimeline(id, params) {
      if (timelines.current) {
        timelines.current[id] = anime.timeline(params);

        return timelines.current[id];
      }

      return null;
    },
    add(id, params) {
      if (timelines.current && timelines.current[id]) {
        return timelines.current[id].add(params);
      }

      return null;
    },
    play(id: string) {
      timelines.current[id].play();
    },
    pause(id: string) {
      timelines.current[id].pause();
    },
    reverse(id: string) {
      timelines.current[id].reverse();
    },
    seek(id: string, seekPoint: number) {
      timelines.current[id].seek(seekPoint);
    },
  };

  return <Provider value={store}>{children}</Provider>;
}

export const useAnimation = () => useContext(AnimationContext);
