import {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
  useEffect,
} from 'react';

import jwtDecode from 'jwt-decode';

import {
  Cart,
  CartItem,
  MetaData,
  Customer,
  useGetCartQuery,
} from '@axis/graphql';
import { isSSR } from '@axis/utils/ssr';

export interface Session {
  cart: Cart|null;
  customer: Customer|null;
  pathToCheckout: string|false;
  fetchingCart: boolean;
  setCart: (cart: Cart) => void;
  setCustomer: (customer: Customer) => void;
  findInCart: (productId: number, variationId?: number, extraData?: string) => CartItem|undefined;
}

const initialSession: Session = {
  cart: null,
  customer: null,
  pathToCheckout: false,
  fetchingCart: false,
  setCart: (cart: Cart) => {},
  setCustomer: (customer: Customer) => {},
  findInCart: (productId: number, variationId?: number, extraData?: string) => undefined,
};

export type DecodedToken = {
  data: {
    customer_id: string;
    checkout_url: string;
  };
};

/**
 * Checks if product matches the provided cart/registry item.
 *
 * @param {number} productId Item product ID.
 * @param {number} variationId Item variation ID.
 * @param {string} extraData Item metadata JSON string.
 * @returns
 */
const cartItemSearch = (
  productId: number,
  variationId?: number,
  extraData?: string,
  skipMeta = false,
) => ({
  product,
  variation,
  extraData:
  existingExtraData = [],
}: CartItem) => {
  if (product?.node?.databaseId && productId !== product.node.databaseId) {
    return false;
  }

  if (
    variation?.node?.databaseId
      && variationId !== variation.node.databaseId
  ) {
    return false;
  }

  if (skipMeta) {
    return true;
  }

  if (existingExtraData?.length && !extraData) {
    return false;
  }

  if (!!extraData && typeof extraData === 'string') {
    const decodeMeta = JSON.parse(extraData);
    let found = false;
    Object.entries(decodeMeta).forEach(([targetKey]) => {
      found = !!(existingExtraData as MetaData[])?.find(
        ({ key, value }) => key === targetKey && value === `${decodeMeta[targetKey]}`,
      );
    });

    if (!found) {
      return false;
    }
  }

  return true;
};

export const SessionContext = createContext<Session>(initialSession);

type SessionAction = {
  type: 'SET_CART';
  payload: Cart;
} | {
  type: 'SET_CUSTOMER';
  payload: Customer;
} | {
  type: 'SET_CHECKOUT_URL';
  payload: string|false;
}

const reducer = (state: Session, action: SessionAction): Session => {
  switch (action.type) {
    case 'SET_CART':
      return {
        ...state,
        cart: action.payload,
      };
    case 'SET_CUSTOMER':
      return {
        ...state,
        customer: action.payload,
      };
    case 'SET_CHECKOUT_URL':
      return {
        ...state,
        pathToCheckout: action.payload,
      };
    default:
      throw new Error('Invalid action dispatched to session reducer');
  }
};

const { Provider } = SessionContext;

export function SessionProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialSession);

  const { data, loading: fetchingCart } = useGetCartQuery({ skip: isSSR() });

  useEffect(() => {
    if (data?.cart) {
      dispatch({
        type: 'SET_CART',
        payload: data.cart as Cart,
      });
    }

    if (data?.customer) {
      dispatch({
        type: 'SET_CUSTOMER',
        payload: data.customer as Customer,
      });
    }

    if (data?.customer?.sessionToken) {
      const decodedToken = jwtDecode<DecodedToken>(data?.customer?.sessionToken);
      const checkoutUrl = decodedToken?.data?.checkout_url
        ? `${process.env.CHECKOUT_BASE}${decodedToken.data.checkout_url.replace(/&amp;/g, '&')}`
        : false;

      dispatch({
        type: 'SET_CHECKOUT_URL',
        payload: checkoutUrl,
      });
    }
  }, [data]);

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    const newSessionToken = state.customer?.sessionToken;
    const currentSessionToken = localStorage.getItem(process.env.SESSION_TOKEN_LS_KEY as string);
    if (newSessionToken && (!currentSessionToken || currentSessionToken !== newSessionToken)) {
      localStorage.setItem(process.env.SESSION_TOKEN_LS_KEY as string, newSessionToken as string);
    }
  }, [state.customer?.sessionToken]);

  const setCart = (cart: Cart) => dispatch({
    type: 'SET_CART',
    payload: cart,
  });

  const setCustomer = (customer: Customer) => dispatch({
    type: 'SET_CUSTOMER',
    payload: customer,
  });

  const findInCart = (productId: number, variationId?: number, extraData?: string) => {
    const items = state?.cart?.contents?.nodes as CartItem[];
    if (!items) {
      return undefined;
    }
    return items.find(cartItemSearch(productId, variationId, extraData, true)) || undefined;
  };

  const store: Session = {
    ...state,
    fetchingCart,
    setCart,
    setCustomer,
    findInCart,
  };
  return (
    <Provider value={store}>{children}</Provider>
  );
}

export const useSession = () => useContext(SessionContext);
