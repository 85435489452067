/* eslint-disable import/prefer-default-export */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';

export async function ssrFetchAll<Q, T>(
  client: ApolloClient<NormalizedCacheObject>,
  queryDocument: DocumentNode,
  queryPath: string,
  pageSize?: number,
  where?: any,
): Promise<T[]> {
  let dataList: T[] = [];
  let data: any = null;
  let after = '';
  do {
    const results = await client.query<Q>({
      query: queryDocument,
      variables: {
        first: pageSize || 50,
        after,
        where,
      },
    });

    data = results?.data;
    if (!data[queryPath]) {
      throw new Error('SSR fetch all failed');
    }

    after = data[queryPath]?.pageInfo?.endCursor;
    dataList = [...dataList, ...data[queryPath].edges.map(({ node }: { node: unknown }) => node)];
  } while (data[queryPath]?.pageInfo?.hasNextPage);

  return dataList;
}
