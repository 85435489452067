/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './node_modules/tw-elements/dist/js/**/*.js',
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ['Fira Sans Condensed', 'sans-serif'],
        serif: ['Vollkorn', 'serif'],
      },
      colors: {
        champagne: {
          50: '#FEF7F6',
          100: '#FBECE6',
          200: '#F9E9DD',
          300: '#F6E8CD',
          400: '#BFCDAB',
          500: '#89A38E',
          600: '#677A79',
          700: '#444750',
          800: '#242127',
        },
        cornsilk: {
          50: '#FFF8F7',
          100: '#FFF4EF',
          200: '#FFF2E6',
          300: '#FFF2DE',
        },
        'russian-green': {
          50: '#E8DFD9',
          100: '#D1CCB6',
          200: '#B1BA95',
          300: '#87A378',
          400: '#5E8C61',
          500: '#557868',
          600: '#4A6364',
          700: '#3E464F',
          800: '#30303B',
          900: '#242127',
        },
        cobalt: {
          400: '#94B0DA',
        },
        'space-cadet': {
          500: '#7B92BE',
          600: '#6475A1',
          700: '#4F5A85',
          800: '#3B4268',
          900: '#292C4C',
        },
        raisin: {
          500: '#74799E',
          600: '#636281',
          700: '#524E63',
          800: '#3C3845',
          900: '#242127',
        },
      },
    },
    screens: {
      sm: '480px',
      md: '768px',
      lg: '976px',
      xl: '1440px',
    },
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
  ],
};
