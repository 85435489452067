import {
  useState, ReactNode, useEffect,
} from 'react';
import { useRouter } from 'next/router';

import { useSession } from '@axis/components/SessionProvider';
import { useAnimation } from '@axis/components/AnimationProvider';
import { Logo } from '@axis/components/Logo';
import { Basket } from '@axis/components/Basket';
import { Link } from '@axis/components/Link';

interface WrapperProps {
  showingCart: boolean;
  children: ReactNode;
}

export interface NavbarProps {
  disabled?: boolean;
}

export function Navbar({ disabled }: NavbarProps) {
  const [showingBasket, setShowingBasket] = useState(false);
  const { cart } = useSession();
  const { pathname } = useRouter();

  const { newTimeline, add, anime } = useAnimation();

  useEffect(() => {
    newTimeline(
      'navbar-items',
      {
        direction: 'normal',
        autoplay: true,
        easing: 'easeInOutSine',
      },
    );
    add(
      'navbar-items',
      {
        targets: '.navbar .logo_svg__logo-bg',
        opacity: [0, 1],
        duration: 200,
        easing: 'linear',
      },
    );
    add(
      'navbar-items',
      {
        targets: '.navbar .logo_svg__logo-ladle',
        opacity: [0, 1],
        translateY: [-200, 0],
        translateX: [200, 0],
        duration: 300,
        easing: 'linear',
        delay: 200,
      },
    );
    add(
      'navbar-items',
      {
        targets: '.navbar .logo_svg__logo-needle',
        opacity: [0, 1],
        translateY: [-200, 0],
        translateX: [-200, 0],
        duration: 300,
        easing: 'linear',
        delay: 200,
      },
    );

    add(
      'navbar-items',
      {
        targets: '.navbar h1, .navbar a, .navbar button',
        translateY: [-200, 0],
        opacity: [0, 1],
        duration: 300,
        delay: anime.stagger(300, { start: 300 }),
      },
    );
  }, []);

  useEffect(() => {
    if (showingBasket) {
      anime(
        {
          targets: '.basket-drawer',
          translateX: [500, 0],
          opacity: [0, 1],
          duration: 500,
          delay: 100,
          easing: 'easeInOutSine',
        },
      );
      anime(
        {
          targets: '.basket-drawer-overlay',
          opacity: [0, 0.8],
          duration: 500,
          easing: 'easeInOutSine',
        },
      );
    }
  }, [showingBasket]);

  const closeDrawer = () => {
    anime(
      {
        targets: '.basket-drawer',
        translateX: [0, 500],
        opacity: [1, 0],
        duration: 500,
        easing: 'easeInOutSine',
        complete: () => setShowingBasket(false),
      },
    );
    anime(
      {
        targets: '.basket-drawer-overlay',
        opacity: [0.8, 0],
        duration: 300,
        easing: 'easeInOutSine',
      },
    );
  };

  return (
    <>
      <nav className="navbar bg-russian-green-400 text-champagne-300 sticky top-0 z-40">
        <div
          className="max-w-screen-sm
        md:max-w-screen-md
        lg:max-w-screen-lg
        xl:max-w-screen-xl
        mx-auto
        px-2
        sm:px-6
        lg:px-8"
        >
          <div className={`relative flex ${disabled ? 'justify-center' : 'justify-between'} items-center h-20`}>
            <Logo className="justify-self-start" />
            <div className={`${disabled && 'hidden'} inline-flex justify-self-center font-sans font-bold text-lg`.trim()}>
              <Link href="/" className={`navbar-link ${pathname === '/' && 'active'}`.trim()}>Home</Link>
              <Link href="/shop" className={`navbar-link ${pathname.includes('shop') && 'active'}`.trim()}>Shop</Link>
            </div>
            <button
              type="button"
              className={`${disabled && 'hidden'} icon-button navbar-link text-lg`.trim()}
              onClick={() => setShowingBasket(true)}
            >
              <span className="sr-only">Show shopping cart</span>
              <i className="fa-solid fa-basket-shopping" />
              <span className="font-sans text-base font-bold ml-2">{cart?.contents?.itemCount}</span>
            </button>
          </div>
        </div>
      </nav>
      {showingBasket && (
        <>
          <div
            className="basket-drawer-overlay drawer-overlay z-50"
            onClick={closeDrawer}
            onKeyPress={closeDrawer}
            role="button"
            aria-label="overlay"
            tabIndex={-1}
          />
          <div
            className="basket-drawer drawer
          min-h-full
          max-h-full
          left-0
          top-0
          w-full
          lg:left-auto
          lg:right-0
          lg:w-4/6
          z-50"
          >
            <button
              type="button"
              className="drawer-close-button z-50"
              onClick={closeDrawer}
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="drawer-header">
              <i className="fa-solid fa-basket-shopping mr-2" />
              Basket
            </div>
            <div className="drawer-content">
              <Basket />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
