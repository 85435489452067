import Image from 'next/image';

import { useState } from 'react';

import { useIsMobile } from '@axis/hooks/mobile';
import {
  CartItem,
  Product,
  SimpleProduct,
  StockStatusEnum,
  useGetProductQuery,
  useGetProductVariationQuery,
} from '@axis/graphql';
import useCartMutations from '@axis/hooks/useCartMutations';

export interface BasketItemProps {
  item: CartItem;
}
export function BasketItem({ item }: BasketItemProps) {
  const isMobile = useIsMobile();
  const slug = item.product?.node?.slug as string;
  const productId = item.product?.node?.databaseId as number;
  const variationId = item.variation?.node?.databaseId || undefined;

  const {
    quantityFound,
    removeCartItem,
    updateQuantity,
  } = useCartMutations(productId, variationId);
  const [quantity, setQuantity] = useState(quantityFound);
  const { data: productData, loading: fetchingProduct } = useGetProductQuery({
    variables: { slug },
    skip: !slug,
  });

  const { data: variationData, loading: fetchingVariation } = useGetProductVariationQuery({
    variables: { id: `${variationId as number}` },
    skip: !variationId,
  });

  if (fetchingProduct || fetchingVariation || (!productData && !variationData)) {
    return null;
  }

  let product;
  if (variationData) {
    product = variationData.productVariation;
  } else {
    product = productData?.product;
  }

  const maxQuantity = StockStatusEnum.InStock === (product as SimpleProduct).stockStatus
    && (product as SimpleProduct).stockQuantity
    ? (product as SimpleProduct).stockQuantity as number
    : undefined;

  return (
    <>
      {!isMobile && (
        <div className="col-span-1">
          <div className="w-full h-32 relative">
            <Image
              src={product?.image?.sourceUrl as string}
              alt={product?.image?.altText as string}
              objectFit="contain" // change to suit your needs
              layout="fill"
            />
          </div>
        </div>
      )}
      <div className="col-span-1 md:col-span-2 flex flex-col items-start justify-center px-5">
        <div className="font-sans">{product?.name}</div>
        <button
          className="font-sans text-xs w-auto "
          type="button"
          onClick={removeCartItem}
        >
          (Remove item)
        </button>
      </div>
      <div className="col-span-1 flex flex-col justify-center">
        <label htmlFor="quantity" className="mb-2 text-sm font-medium sr-only">{`Number of ${item.product?.node?.name} in basket`}</label>
        <div className="relative">
          <input
            type="number"
            id="quantity"
            className="form-field-input appearance-none block p-4 pl-10 w-full"
            value={quantity}
            onChange={(event) => setQuantity(parseInt(event.target.value, 10))}
            min={1}
            max={maxQuantity}
          />
          <button
            type="submit"
            className="icon-button secondary-button border-none text-russian-green-200 absolute right-2.5 bottom-2.5 focus:outline-none focus:border-russian-green-400 hover:border-russian-green-400"
            onClick={() => updateQuantity(quantity)}
          >
            <i className="fa-solid fa-cart-arrow-down align-top" />
          </button>
        </div>
      </div>

      {!isMobile && (
        <div className="col-span-1 font-bold text-center font-sans flex items-center justify-center">
          {(product as SimpleProduct).price}
        </div>
      )}
      <div className="col-span-1 font-bold font-sans flex items-center justify-center">
        {item.total}
      </div>
    </>
  );
}
