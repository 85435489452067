import { useSession } from '@axis/components/SessionProvider';
import { Form, Field } from 'react-final-form';

import STATES from '@axis/utils/us-states';
import { useOtherCartMutations } from '@axis/hooks/useCartMutations';
import type { SetShippingLocaleInput } from '@axis/hooks/useCartMutations';
import { ShippingRate } from '@axis/graphql';

interface ShippingLocalFormProps {
  onSubmit: (values: SetShippingLocaleInput) => void;
  disabled?: boolean;
  message: string;
}

export function ShippingLocaleForm(props: ShippingLocalFormProps) {
  const { onSubmit, disabled, message } = props;

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="px-5 mb-5 border-b-2 border-cornsilk-300 md:border-none">
          <p className="w-full px-5 flex justify-between text-center mb-3 font-sans text-sm text-gray-600">
            {message}
          </p>
          <div className="w-full form-field-wrapper">
            <span className="font-bold">City</span>
            <Field
              className="form-field-input w-4/5 ml-auto"
              component="input"
              type="text"
              name="city"
            />
          </div>
          <div className="w-full form-field-wrapper">
            <span className="font-bold">State</span>
            <div className="relative w-4/5 ml-auto">
              <Field
                className="form-field-select w-full"
                component="select"
                type="text"
                name="state"
              >
                {STATES.map(({ name, abbreviation }) => (
                  <option value={abbreviation}>{name}</option>
                ))}
              </Field>
              <div className="form-field-select-pointer">
                <span className="icon-button">
                  <i className="fa-solid fa-chevron-down text-xs" />
                </span>
              </div>
            </div>
          </div>
          <div className="w-full form-field-wrapper">
            <span className="font-bold">Zip</span>
            <Field
              className="form-field-input w-4/5 ml-auto"
              component="input"
              type="text"
              name="zip"
            />
          </div>
          <div className="w-full flex justify-center mt-3 py-1 mb-5">
            <button
              type="submit"
              className="secondary-button w-full"
              disabled={disabled}
            >
              Calculate Shipping
            </button>
          </div>
        </form>
      )}
    </Form>
  );
}

export function BasketShipping() {
  const { cart, customer } = useSession();
  const {
    setShippingLocale,
    savingShippingInfo,
    setShippingMethod,
  } = useOtherCartMutations();
  const onShippingLocaleSubmit = async (values: SetShippingLocaleInput) => {
    await setShippingLocale(values);
  };

  if (!cart?.needsShippingAddress) {
    return null;
  }

  const availableShippingRates: ShippingRate[] = (cart?.availableShippingMethods || [])
    .reduce<ShippingRate[]>(
      (rates, nextPackage) => {
        rates.push(...(nextPackage?.rates || []) as ShippingRate[]);

        return rates;
      },
      [] as ShippingRate[],
    );

  if (customer?.shipping?.postcode && !availableShippingRates.length) {
    return (
      <ShippingLocaleForm
        onSubmit={onShippingLocaleSubmit}
        disabled={savingShippingInfo}
        message="Sorry, we currently are shipping to your location. Try a different location."
      />
    );
  }

  if (customer?.shipping?.postcode) {
    return (
      <>
        {availableShippingRates.map(({ cost, id, label }) => (
          <div className="radio-button-wrapper mr-4 mb-4 px-5">
            <label className="radio-button-label">
              <input
                type="radio"
                name="shipping-methods"
                value={id}
                className="hidden"
                disabled={savingShippingInfo}
                onChange={(event) => setShippingMethod(event.target.value)}
              />
              <span className="radio-button" />
              {`${label}: `}
              &nbsp;
              <span className="font-bold">{`$${cost}`}</span>
            </label>
          </div>
        ))}
        <div className="w-full flex justify-between px-5 font-sans">
          <span className="font-bold text-lg">Shipping Tax</span>
          <span className="font-bold text-lg">{cart?.shippingTax}</span>
        </div>
        <div className="w-full flex justify-between px-5 font-sans">
          <span className="font-bold text-lg">Shipping Total</span>
          <span className="font-bold text-lg">{cart?.shippingTotal}</span>
        </div>
      </>
    );
  }

  return (
    <ShippingLocaleForm
      onSubmit={onShippingLocaleSubmit}
      disabled={savingShippingInfo}
      message="Shipping and additional costs are calculated based on values you have entered"
    />
  );
}
