import { BasketList } from '@axis/components/BasketList';
import { BasketInfo } from '@axis/components/BasketInfo';
import { CheckoutButton } from '@axis/components/CheckoutButton';

export function Basket() {
  return (
    <div className="flex flex-col items-center justify-center px-1 w-5/6 mx-auto">
      <BasketList />
      <BasketInfo />
      <CheckoutButton />
    </div>
  );
}

export default Basket;
