/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  TypePolicies,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

import { isSSR } from '@axis/utils/ssr';
import introspectionData from '../possibleTypes.json';

type MiddlewareHeaders = { 'woocommerce-session'?: string };

type PageProps = {
  __APOLLO_STATE__: NormalizedCacheObject
}

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject;
  }
}

const windowApolloState: NormalizedCacheObject = typeof window !== 'undefined' ? window.__APOLLO_STATE__ : {};

let apolloClient: ApolloClient<NormalizedCacheObject>;

const typePolicies = {
  RootQuery: {
    queryType: true,
    fields: {
      products: relayStylePagination(['where']),
    },
  },
  RootMutation: {
    mutationType: true,
  },
  CartItem: {
    keyFields: ['key'],
  },
  LineItem: {
    keyFields: ['databaseId'],
  },
  Order: {
    fields: {
      lineItems: relayStylePagination(['where']),
    },
  },
};

function createSessionLink() {
  return setContext(async (operation) => {
    if (isSSR()) {
      return {};
    }
    const headers: MiddlewareHeaders = {};
    const sessionToken = localStorage.getItem(process.env.SESSION_TOKEN_LS_KEY as string);

    if (sessionToken) {
      headers['woocommerce-session'] = `Session ${sessionToken}`;

      return { headers };
    }

    return {};
  });
}

function createErrorLink() {
  return onError(({
    graphQLErrors, operation, forward,
  }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => new Observable((observer) => {
        if (message === 'Expired token' || message === 'Wrong number of segments') {
          fetch('/api/session', { method: 'POST' })
            .then((response) => response.json())
            .then(({ sessionToken = false }) => {
              localStorage.removeItem(process.env.SESSION_TOKEN_LS_KEY as string);

              operation.setContext(({ headers = {} }) => {
                const nextHeaders: MiddlewareHeaders = headers;

                if (sessionToken) {
                  localStorage.setItem(process.env.SESSION_TOKEN_LS_KEY as string, sessionToken);
                  nextHeaders['woocommerce-session'] = `Session ${sessionToken}`;
                } else {
                  delete nextHeaders['woocommerce-session'];
                }

                return {
                  headers: nextHeaders,
                };
              });
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              observer.error(error);
            });
        }
      }));
    }
  });
}

function createApolloClient() {
  const sessionLink = createSessionLink();
  const errorLink = createErrorLink();
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    connectToDevTools: typeof window !== 'undefined',
    link: ApolloLink.from([
      sessionLink,
      errorLink,
      new HttpLink({
        uri: process.env.GRAPHQL_ENDPOINT,
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: introspectionData.possibleTypes,
      typePolicies: typePolicies as TypePolicies,
    }).restore(windowApolloState),
  });
}

export function initializeApollo(initialState: NormalizedCacheObject|null = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props: PageProps },
) {
  const newPageProps = { ...pageProps };
  if (newPageProps?.props) {
    newPageProps.props.__APOLLO_STATE__ = client.cache.extract();
  }

  return newPageProps;
}

export function useApollo(pageProps: PageProps) {
  const state = pageProps.__APOLLO_STATE__;
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
