import { useIsMobile } from '@axis/hooks/mobile';
import { useSession } from '@axis/components/SessionProvider';
import { BasketItem } from '@axis/components/BasketItem';
import { CartItem } from '@axis/graphql';

export function BasketList() {
  const isMobile = useIsMobile();
  const { cart } = useSession();
  const items = (cart?.contents?.nodes) as CartItem[];

  return (
    <div className="w-full mt-5 pb-5 md:pb-3 border-b-2 border-cornsilk-300 grid grid-cols-3 md:grid-cols-6 gap-y-3 md:gap-y-6 gap-x-0">
      <div className="drawer-subheader flex items-center col-span-1 md:col-span-3 rounded-l">Product</div>
      <div className="drawer-subheader md:px-0 flex items-center justify-start col-span-1">
        {isMobile ? 'QTY' : 'Quantity'}
      </div>
      {!isMobile && (
        <div className="drawer-subheader flex items-center col-span-1">Unit Price</div>
      )}
      <div className="drawer-subheader flex items-center col-span-1 rounded-r">Total</div>
      {items.map((item) => <BasketItem key={item.key} item={item} />)}
    </div>
  );
}

export default BasketList;
