import { PropsWithChildren } from 'react';
import NextLink from 'next/link';

export type LinkProps = JSX.IntrinsicElements['a'] & {
  href: string;
}

export function Link({ children, href, ...rest }: PropsWithChildren<LinkProps>) {
  return (
    <NextLink href={href} passHref>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <a {...rest}>{children}</a>
    </NextLink>
  );
}
