/* eslint-disable react/jsx-props-no-spreading */
import { ApolloProvider } from '@apollo/client';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';

import { useApollo } from '@axis/graphql/client';
import { SessionProvider } from '@axis/components/SessionProvider';
import { AnimationProvider } from '@axis/components/AnimationProvider';
import { Navbar } from '@axis/components/Navbar';
// normalize CSS across browsers
import '@axis/styles/normalize.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/solid.css';

// custom CSS styles
import '@axis/styles/global.css';
import '@axis/styles/style.css';

function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  const { pathname } = useRouter();

  return (
    <ApolloProvider client={apolloClient}>
      <SessionProvider>
        <AnimationProvider>
          <Navbar disabled={pathname === '/'} />
          <Component {...pageProps} />
        </AnimationProvider>
      </SessionProvider>
    </ApolloProvider>
  );
}

export default App;
