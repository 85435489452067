import { useState } from 'react';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

import { theme } from '@axis/tailwind.config.js';
import { isLessThanBreakpoint } from '@axis/utils/mobile';
import type { ScreensConfig } from 'tailwindcss/types/config';

type AxisScreensConfig = {
  sm: string;
  md: string;
} & ScreensConfig

// Creates a hook for tracking whether the current window is below a certain breakpoint
const createHookFromBreakpoint = (breakpoint: string) => (): boolean => {
  const [belowBreakpoint, setBelowBreakpoint] = useState(
    isLessThanBreakpoint(breakpoint),
  );
  const onWindowResize = useWindowResize();

  const options = {
    leading: false,
    trailing: true,
  };

  const onWindowResizeHandler = useThrottledCallback(
    () => {
      setBelowBreakpoint(isLessThanBreakpoint(breakpoint));
    },
    [breakpoint],
    500,
    options,
  );

  onWindowResize(onWindowResizeHandler);

  return belowBreakpoint;
};

export const useIsMobile = createHookFromBreakpoint((theme?.screens as AxisScreensConfig)?.sm);

export const useIsTabletOrMobile = createHookFromBreakpoint(
  (theme?.screens as AxisScreensConfig)?.md,
);
