import { useEffect, useState } from 'react';

import { useSession } from '@axis/components/SessionProvider';
import {
  useAddToCartMutation,
  useUpdateCartItemQuantitiesMutation,
  useRemoveItemsFromCartMutation,
  useApplyCouponToCartMutation,
  useRemoveCouponFromCartMutation,
  useSetShippingLocaleMutation,
  useSetShippingMethodMutation,
  Cart,
  CountriesEnum,
  Customer,
  GetCartDocument,
} from '@axis/graphql';

export interface CartMutationInput {
  mutation: 'add'|'update'|'remove';
  quantity: number;
  all?: boolean;
}

export interface SetShippingLocaleInput {
  zip: string;
  state?: string;
  city?: string;
}

const useCartMutations = (
  productId: number,
  variationId?: number,
  extraData?: string,
) => {
  const {
    cart,
    setCart,
    findInCart,
  } = useSession();
  const [quantityFound, setQuantityInCart] = useState(
    findInCart(productId, variationId, extraData)?.quantity as number || 0,
  );

  const [addToCart, { loading: adding }] = useAddToCartMutation({
    onCompleted({ addToCart: data }) {
      if (data?.cart) {
        setCart(data.cart as Cart);
      }
    },
  });
  const [updateQuantity, { loading: updating }] = useUpdateCartItemQuantitiesMutation({
    onCompleted({ updateItemQuantities: data }) {
      if (data?.cart) {
        setCart(data.cart as Cart);
      }
    },
  });
  const [removeCartItem, { loading: removing }] = useRemoveItemsFromCartMutation({
    onCompleted({ removeItemsFromCart: data }) {
      if (data?.cart) {
        setCart(data.cart as Cart);
      }
    },
  });

  useEffect(() => {
    setQuantityInCart(
      findInCart(productId, variationId, extraData)?.quantity || 0,
    );
  }, [productId, variationId, extraData, cart?.contents?.nodes]);

  const mutate = async (values: CartMutationInput) => {
    const {
      quantity,
      all = false,
      mutation = 'update',
    } = values;

    if (!cart) {
      return;
    }

    if (!productId) {
      throw new Error('No item provided.');
      // TODO: Send error to Sentry.IO.
    }

    switch (mutation) {
      case 'remove': {
        if (!quantityFound) {
          throw new Error('Provided item not in cart');
        }

        const item = findInCart(
          productId,
          variationId,
          extraData,
        );

        if (!item) {
          throw new Error('Failed to find item in cart.');
        }

        const { key } = item;
        removeCartItem({ variables: { keys: [key], all } });
        break;
      }
      case 'update':
      default:
        if (quantityFound) {
          const item = findInCart(
            productId,
            variationId,
            extraData,
          );

          if (!item) {
            throw new Error('Failed to find item in cart.');
          }

          const { key } = item;
          updateQuantity({ variables: { items: [{ key, quantity }] } });
        } else {
          addToCart({
            variables: {
              quantity,
              productId,
              variationId,
              extraData,
            },
          });
        }
        break;
    }
  };

  const removeCartItemHelper = () => {
    const item = findInCart(productId, variationId, extraData);

    if (!item) {
      throw new Error('Failed to find item in cart.');
    }

    removeCartItem({ variables: { keys: [item.key] } });
  };

  const updateQuantityHelper = (quantity: number) => {
    const item = findInCart(productId, variationId, extraData);

    if (!item) {
      throw new Error('Failed to find item in cart.');
    }

    updateQuantity({ variables: { items: [{ key: item.key, quantity }] } });
  };

  const addToCartHelper = (
    pId: number,
    quantity: number,
    vId?: number,
    e?: string,
  ) => {
    addToCart({
      variables: {
        productId: pId,
        quantity,
        variationId: vId,
        extraData: e,
      },
    });
  };

  const store = {
    adding,
    updating,
    removing,
    quantityFound,
    mutate,
    addToCart: addToCartHelper,
    removeCartItem: removeCartItemHelper,
    updateQuantity: updateQuantityHelper,
  };

  return store;
};

export const useOtherCartMutations = () => {
  const {
    setCart,
    setCustomer,
  } = useSession();

  const [applyCouponToCart, { loading: applyingCoupon }] = useApplyCouponToCartMutation({
    refetchQueries: [GetCartDocument],
    onCompleted({ applyCoupon: data }) {
      if (data?.cart) {
        setCart(data.cart as Cart);
      }
    },
  });

  const [removeCouponFromCart, { loading: removingCoupon }] = useRemoveCouponFromCartMutation({
    refetchQueries: [GetCartDocument],
    onCompleted({ removeCoupons: data }) {
      if (data?.cart) {
        setCart(data.cart as Cart);
      }
    },
  });

  const [setShippingLocale, { loading: savingShippingLocale }] = useSetShippingLocaleMutation({
    refetchQueries: [GetCartDocument],
    onCompleted({ updateCustomer: data }) {
      if (data?.customer) {
        setCustomer(data.customer as Customer);
      }
    },
  });

  const [setShippingMethod, { loading: savingShippingMethod }] = useSetShippingMethodMutation({
    refetchQueries: [GetCartDocument],
    onCompleted({ updateShippingMethod: data }) {
      if (data?.cart) {
        setCart(data.cart as Cart);
      }
    },
  });

  const applyCouponHelper = (code: string) => applyCouponToCart({ variables: { code } });
  const removeCouponHelper = (code: string) => removeCouponFromCart({ variables: { code } });
  const setShippingLocaleHelper = (input: SetShippingLocaleInput) => setShippingLocale({
    variables: {
      ...input,
      country: CountriesEnum.Us,
    },
  });

  const setShippingMethodHelper = (shippingMethod: string) => setShippingMethod({
    variables: { shippingMethod },
  });

  const store = {
    applyingCoupon,
    removingCoupon,
    savingShippingInfo: savingShippingLocale || savingShippingMethod,
    applyCoupon: applyCouponHelper,
    removeCoupon: removeCouponHelper,
    setShippingLocale: setShippingLocaleHelper,
    setShippingMethod: setShippingMethodHelper,
  };

  return store;
};

export default useCartMutations;
